var jQuery = require('jquery');
var $ = jQuery;
var tools = require('./functions');
var carousel = require('slick-carousel');
// var foundation = require('foundation-sites');

$(document).ready(function(){
    // console.log('test')
    $('.carousel').slick({
        dots: false,
        autoplay: true,
        adaptiveHeight: true,
        infinite: false,
        centerMode: false,
        autoplaySpeed: 1700,
        arrows: false
    })
    // .on('afterChange', function (event, slick, currentSlide) {
    //
    //     var h = $("[data-slick-index='"+currentSlide+"']").height();
    //
    //     $(".fili").css({
    //         "top" : (h-100)+"px"
    //     });
    // });
});
